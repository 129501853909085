import clsx from "clsx"
import { signOut } from "next-auth/react"
import { useState } from "react"
import styles from "./auth.module.scss"
import ForgotPassword from "./forgotPassword"
import Layout from "./layout"
import Modal from "./modal"
import SignIn from "./signIn"
import SignUp from "./signUp"
import { useSession } from "components/auth/useSession"

const decorationLetterName = (name: string) => {
    if (!name) {
        return ''
    }
    if (name.length <= 2) {
        return name
    }
    const splitString = name.split(' ');
    if (splitString.length === 1) {
        return splitString[0].charAt(0);
    }
    if (splitString.length > 1) {
        return splitString[0].charAt(0) + splitString[1].charAt(0);
    }

}
export default function Component({isMobileApp}: {isMobileApp?: boolean}) {
    const { data: session } = useSession(true)
    const [form, setForm] = useState("")
   
    return (
        <>
            {session?.user ? (
                <div className={clsx(styles.userImageContainer,styles.userContainerMobile)}>
        
                    <a  href="/profile" className={styles.goToProfile}>
                        {session.user.image
                            ? <img src={session.user.image} alt={session.user.name!} referrerPolicy="no-referrer" className={styles.user} />
                            : <div className={clsx(styles.user, styles.accountLetter)}>{decorationLetterName(session.user.name ?? "")}</div>
                        }
                        {session.user.name && <p className={styles.userName}>{session.user.name}</p>}
                      
                    </a>
                    <button onClick={() => signOut({ callbackUrl: "/" })} className={clsx("svgIcon", styles.iconLogout)}><svg><use xlinkHref="#iconLogout"></use></svg></button>
                    </div>
          
            ) : <button className={clsx("svgIcon", styles.userContainerMobile)} onClick={() => setForm("signIn")}>
                <svg className={styles.user}> <use xlinkHref="#IconUser"></use> </svg>
                <p>ΕΙΣΟΔΟΣ/ ΕΓΓΡΑΦΗ</p>
            </button>
            }

            <Modal show={!!form} onClose={() => setForm("")}>
                <Layout form={form} setForm={setForm}>
                    {form === 'signIn' && <SignIn setForm={setForm} isMobileApp={isMobileApp}/>}
                    {form === 'signUp' && <SignUp setForm={setForm} />}
                    {form === 'forgotPassword' && <ForgotPassword setForm={setForm} />}
                </Layout>
            </Modal>
        </>
    )
}
